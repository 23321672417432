<script lang="ts">
  import type { SanityPicture } from "$lib/types/shared";
  import Picture from "./SanityPicture.svelte";
  import Button from "./Button.svelte";
  import newDate from "./utils/time";
  import { goto, preloadData } from "$app/navigation";
  import { breakpoints as br } from "$lib/utils/getBreakpoints";
  import lang from "$lib/langStore";

  const preloadHref = () => {
    preloaded = true;
    if (!preloaded && href) preloadData(href);
  };
  const gotoHref = async (e: Event) => {
    if (!href) return;
    if (e.preventDefault) e.preventDefault();
    if (href.startsWith("http")) window.location.href = href;
    else {
      preloadHref();
      await goto(href);
    }
  };

  interface Props {
    rel?: string | undefined;
    size?: "full" | null; // changes layout on bigger screens based on size
    href?: string | null;
    title?: string | null;
    date?: string | null;
    microformats?: boolean; // https://developer.mozilla.org/en-US/docs/Web/HTML/microformats#h-feed
    textHeight?: string | null; // A CSS size value for a fixed text height
    buttonStyle?: "button" | "button-primary" | "link" | "none";
    circleImage?: boolean;
    buttonArrow?: boolean;
    reverse?: boolean;
    transparent?: boolean;
    // Picture props
    pictureObject?: SanityPicture | null;
    imgAlt?: string;
    pictureClass?: string;
    // (sizes for full card style and full circle card is handled below in prop)
    imgSizes?: string | null;
    pictureLoading?: "eager" | "lazy";
    class?: string;
    children?: import("svelte").Snippet;
    showButton?: boolean;
  }

  let {
    rel = undefined,
    size = null,
    href = null,
    title = null,
    date = null,
    microformats = false,
    textHeight = null,
    buttonStyle = "button",
    circleImage = false,
    buttonArrow = true,
    reverse = false,
    transparent = false,
    pictureObject = null,
    imgAlt = "",
    pictureClass = "",
    imgSizes = null,
    pictureLoading = "lazy",
    class: classes = "",
    children,
    showButton = true,
  }: Props = $props();
  let preloaded = false;
</script>

<!-- svelte-ignore a11y_no_static_element_interactions -->
<div
  class="card rounded-2xl {classes}"
  class:card-full={size === "full"}
  class:fixed-text-height={textHeight}
  class:card-image-circle={circleImage}
  class:card-reverse={reverse}
  class:transparent
  style={textHeight ? `--text-height: ${textHeight}` : undefined}
  onmouseenter={href ? preloadHref : undefined}
>
  <div class="card-image-container">
    <svelte:element
      this={href ? "button" : "div"}
      tabindex="-1"
      type={href ? "button" : undefined}
      class="card-image-container-inner"
      onclick={href ? gotoHref : undefined}
      aria-label={!imgAlt ? title || href : undefined}
    >
      {#if pictureObject?.src}
        <!-- sizes attr is approx img width for diff breakpoints -->
        <Picture
          loading={pictureLoading}
          {pictureObject}
          sizes={imgSizes
            ? imgSizes
            : size && size === "full" && circleImage
              ? `(min-width: ${br.laptop}) 35vw, (min-width: ${br.tablet}) 45vw, 92vw`
              : size && size === "full"
                ? `(min-width: ${br.laptop}) 50vw, (min-width: ${br.tablet}) 45vw, 92vw`
                : undefined}
          alt={imgAlt}
          class="{pictureClass} {circleImage ? `` : `w-full`} {microformats ? `u-photo` : ``}"
          circle={circleImage}
          width={circleImage ? 400 : undefined}
          height={circleImage ? 400 : undefined}
        />
      {/if}
    </svelte:element>
  </div>

  <div class="pb-3 text-container">
    <div class="card-text max-w-prose overflow-hidden relative">
      {#if date}
        <p class="my-2 relative top-2">
          <time datetime={date} class="text-sm" class:dt-published={microformats}>
            {newDate(date).toLocaleDateString($lang || "nb", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </time>
        </p>
      {/if}

      {#if title}
        <p class="card-title font-semibold mt-3 mb-1" class:p-name={microformats}>
          {#if href}
            <a
              class="button button-link text-start p-0 min-h-[3rem] inline-flex items-center"
              class:underline={!showButton}
              {href}
            >
              {title}
            </a>
          {:else}
            {title}
          {/if}
        </p>
      {/if}

      {@render children?.()}

      {#if href && buttonStyle !== "none"}
        <p class="mt-3 mb-0 button-container text-base min-h-[3rem]" aria-hidden={!showButton}>
          {#if showButton}
            <Button
              href={title ? undefined : href}
              tabindex={title ? -1 : undefined}
              onclick={gotoHref}
              arrow={buttonArrow}
              class="button bg-$bg {microformats ? 'u-url' : ''} {buttonStyle === 'button-primary'
                ? 'button-primary'
                : ''} {buttonStyle === 'link' ? 'button-link' : ''}"
              {rel}
            >
              <span>Les mer</span>

              {#if title || href}
                <span class="sr-only">
                  om <cite>{title || href}</cite>
                </span>
              {/if}
            </Button>
          {/if}
        </p>
      {/if}
    </div>
  </div>
</div>

<style lang="postcss">
  .card {
    --_bg: var(--bg-card, var(--bg));

    position: relative;
    isolation: isolate;
    display: grid;
  }

  .text-container {
    position: relative;
  }

  .card:not(.transparent) {
    background: var(--_bg);

    .text-container {
      /* Background for browsers that struggle with keeping image above (Safari) */
      background: linear-gradient(
        transparent,
        color-mix(in oklab, var(--_bg, var(--bg)), transparent 20%) 3rem
      );
    }
  }

  .card-title {
    font-family: var(--card-title-font, inherit);
    font-size: var(--card-title-size, var(--text-body));
    line-height: var(--card-title-leading, inherit);
    text-wrap: balance;
    text-wrap: pretty;
  }

  /* image */

  .card:not(.card-image-circle) .card-image-container {
    aspect-ratio: 16 / 9;
    overflow: hidden;
  }

  button.card-image-container-inner {
    all: unset;
    box-sizing: border-box;
    display: block;
    width: 100%;
    cursor: pointer; /* `cursor-pointer` is Ios Safari fix */
  }

  .card-image-container-inner :global(img) {
    display: block;
    margin-inline: auto;
  }

  .card.card-image-circle .card-image-container {
    margin-block-end: var(--s-4);
    display: grid;
    place-items: center;
    /* Ios Safari fix */
    width: 100%;
    height: 100%;
  }

  .card:not(.card-image-circle) .card-image-container {
    --picture-aspect-ratio: var(--card-picture-aspect-ratio, 16 / 9);
    border-radius: 1rem;
  }

  .card.card-image-circle :global(img) {
    max-width: min(100%, min(75vw, calc(20 * var(--text-body))));
    max-height: min(100%, min(50vh, calc(20 * var(--text-body))));
  }

  /* button link */

  .button-container :global(.button-link) {
    padding-inline: 0;
    text-decoration-line: underline;
    /* `min-w-0` is Ios Safari fix */
    min-width: 0;
  }

  /* Style for fixed text height and absolute button */

  .fixed-text-height .card-text {
    height: var(--text-height);
    padding-bottom: 3.5em;
  }

  .fixed-text-height .button-container {
    position: absolute;
    width: 100%;
    bottom: 0;
    /* Fade out text below button */
    background: linear-gradient(
      transparent,
      color-mix(in oklab, var(--bg), transparent 12.5%),
      var(--bg)
    );
  }

  /* Card full style */

  @media (--tablet) {
    .card.card-full {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: var(--s-10);
      align-items: center;
    }

    .card.card-full .card-image-container {
      justify-content: end;
    }

    .card.card-full .card-image-container-inner :global(img) {
      display: block;
      margin-inline: auto 0;
    }
  }

  @media (--laptop) {
    .card.card-full .card-title {
      font-size: var(--card-title-size, var(--text-h2));
    }
  }

  /* Reverse order -- image after content */

  .card.card-reverse .card-image-container {
    order: 2;
  }

  @media (--tablet) {
    .card.card-full.card-reverse .card-image-container {
      justify-content: start;
    }
  }
</style>
